$primary: #2369a4;
$warning: #cd7500;
$enable-caret: false;
$table-cell-padding: .25rem;
$border-radius: .15rem;
$input-btn-focus-width: .05rem;
$input-btn-padding-y: .2rem;
$input-btn-padding-x: .35rem;
$font-size-base: 0.9375rem;
$tooltip-opacity: 1;

@import "bootstrap/scss/bootstrap.scss";

.form-group ckeditor .ck-content
{
	@extend .form-control;
	height: auto;
	/**
	border: 1px solid #ced4da;
    border-radius: 0.15rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	*/
}

