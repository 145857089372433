/**
 * Variables.
 */

:root
{
	--doi-background-color: white;
	--doi-secondary-background-color: #f6f6f6;
	--doi-page-header-background-color: #2369a4;
	--doi-page-header-hover-background-color: #5092ce;
	--doi-page-header-tool-background-color: transparent;
	--doi-page-header-tool-color: white;
	--doi-page-header-tool-secondary-color: white;
	--doi-breadcrumb-separator-color: #ccc;
	--doi-text-color: black;
	--doi-negative-text-color: white;
	--doi-label-color: black;
	--doi-header-color: black;
	--doi-row-hover-background-color: #f8f8f8;
	--doi-input-invalid-background-color: #faffbd;
	--doi-item-color: #23527c;
	--doi-item-selected-color: #5097d6;
	--doi-menu-background-color: white;
	--doi-menu-border-color: #ccc;
	--doi-menu-item-hover-background-color: #eee;
	--doi-tool-color: black;
	--doi-tool-hover-background-color: #eee;
	--doi-tool-hover-color: black;
	--doi-tool-disabled-color: #abcbe7;
	--doi-tool-selected-color: #5097d6;
	--doi-tool-working-color: #ff0;
	--doi-tool-padding: 6px;
	--doi-tool-padding-x: var(--doi-tool-padding);
	--doi-tool-padding-y: var(--doi-tool-padding);
	--doi-tool-padding-xs: 2px;
	--doi-tooltip-keymapping-color: #ccc;
	--doi-link-color: #2369a4;
	--doi-link-hover-color: #5092ce;
	--doi-link-focus-color: var(--doi-link-color);
	--doi-card-background-color: #eee;
	--doi-card-hover-background-color: #ccc;
	--doi-card-border-color: #999;
	--doi-card-border-radius: 0.15rem;
	--doi-card-hover-border-color: black;
	--doi-card-text-color: black;
	--doi-card-box-shadow: none;
	--doi-form-control-background-color: white;
	--doi-form-control-border-color: #ced4da;
	--doi-form-control-focus-border-color: #2369a4;
	--doi-checkbox-implied-color: #eee;
	--doi-btn-focus-outline-color: black;
	--doi-btn-primary-background-color: #2369a4;
	--doi-btn-primary-color: white;
	--doi-btn-primary-focus-background-color: #1c5584;
	--doi-btn-primary-focus-border-color: #1a4e7a;
	--doi-btn-secondary-background-color: #6c757d;
	--doi-btn-secondary-color: white;
	--doi-btn-secondary-focus-background-color: #5a6268;
	--doi-btn-secondary-focus-border-color: 545b62;
	--doi-dialog-button-padding-x: 1rem;
	--doi-dialog-button-padding-y: 0.25rem;
	--doi-debug-background-color: #eeeecc;
	--doi-debug-color: #555533;
	--doi-storage-consent-background-color: #101020;
	--doi-storage-consent-text-color: white;

	--doi-dialog-background-color: var(--doi-card-background-color);

	--bs-dropdown-link-hover-bg: var(--doi-menu-item-hover-background-color);
	--bs-inner-table-header-bgcolor: #D3DBEC;
}

/**
 * Bootstrap overrides to use variables for easier theming.
 */

a
{
    color: var(--doi-link-color);
}

a:hover
{
    color: var(--doi-link-hover-color);
}

a:focus
{
    color: var(--doi-link-focus-color);
}

.btn.focus, .btn:focus-visible
{
	outline: 1px dashed var(--doi-btn-focus-outline-color);
	outline-offset: 1px;
}

.btn-primary, .btn-primary:disabled
{
    color: var(--doi-btn-primary-color);
    background-color: var(--doi-btn-primary-background-color);
    border-color: var(--doi-btn-primary-background-color);
}

.btn-primary.focus, .btn-primary:focus
{
    background-color: var(--doi-btn-primary-focus-background-color);
    border-color: var(--doi-btn-secondary-focus-background-color);
	box-shadow: none;
}

.btn-primary:hover
{
    background-color: var(--doi-tool-hover-background-color);
    color: var(--doi-tool-hover-color);
}

.btn-secondary, .btn-secondary:disabled
{
    color: var(--doi-btn-secondary-color);
    background-color: var(--doi-btn-secondary-background-color);
    border-color: var(--doi-btn-secondary-background-color);
}

.btn-secondary.focus, .btn-secondary:focus
{
    background-color: var(--doi-btn-secondary-focus-background-color);
    border-color: var(--doi-btn-secondary-focus-border-color);
	box-shadow: none;
}

.card
{
	background-color: var(--doi-card-background-color);
    border-color: var(--doi-card-border-color);
	box-shadow: var(--doi-card-box-shadow);
}

a .card:hover
{
    background-color: var(--doi-card-hover-background-color);
    border-color: var(--doi-card-hover-border-color);
}

.dropdown-menu
{
    background-color: var(--doi-menu-background-color);
}

.form-control,
.form-control:focus
{
	background-color: var(--doi-form-control-background-color);
	border-color: var(--doi-form-control-border-color);
	color: var(--doi-text-color);
}

.form-control:focus
{
	border-color: var(--doi-form-control-focus-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link
{
    background-color: var(--doi-background-color);
    border-bottom-color: var(--doi-background-color);
    color: var(--doi-link-color);
}

.nav-tabs .nav-link:not(.active):hover
{
    border-color: transparent;
}

.page-link
{
    background-color: var(--doi-card-background-color);
	border-color: var(--doi-card-border-color);
	color: var(--doi-card-text-color);
}

.page-item.active .page-link
{
    background-color: var(--doi-card-border-color);
	border-color: var(--doi-card-border-color);
	color: var(--doi-card-text-color);
}

.page-link:hover
{
    background-color: var(--doi-card-hover-background-color);
	border-color: var(--doi-card-hover-border-color);
	color: var(--doi-card-text-color);
}

.table
{
    color: var(--doi-text-color);
}

.table thead
{
    color: var(--doi-label-color);
}

.table thead th
{
	font-weight: bold;
}

.table-hover tbody tr:hover
{
    color: var(--doi-text-color);
    background-color: var(--doi-row-hover-background-color);
}

/**
 * Basic.
 */

a
{
	text-decoration: none;
}

a:hover
{
	text-decoration: underline;
}

/**
 * Page.
 */

body
{
	background-color: var(--doi-background-color);
    color: var(--doi-text-color);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
{
	color: var(--doi-header-color);
	font-weight: 600;
}

.doi-page-header
{
	background-color: var(--doi-page-header-background-color);
}

.doi-page-header h1 a
{
	display: flex;
	align-items: center;
    color: #f0f0f0;
	margin: 1px;
	padding: 1px;
}

.doi-page-header a:focus,
.doi-page-header li.doi-tool > a:focus
{
	outline: thin dashed var(--doi-page-header-tool-color);
}

.doi-page-header a:focus:not(:focus-visible)
{
	outline: none;
}

.doi-page-header li.doi-tool
{
	background-color: var(--doi-page-header-tool-background-color);
}

.doi-page-header li.doi-tool > a span,
.doi-page-header li.doi-tool.selected > a span
{
    color: var(--doi-page-header-tool-color);
	display: inline-block;
	white-space: nowrap;
	max-width: 15em;
	text-overflow: ellipsis;
}

.doi-page-header doi-search-tool label
{
    color: var(--doi-page-header-tool-color);
}

.doi-page-header li.doi-tool:not(.disabled) > a:hover
{
	background: var(--doi-page-header-hover-background-color);
}

.doi-page-header li.doi-tool.show > a span
{
	color: var(--doi-item-color);
}

.doi-page-header li.doi-tool.show > a:hover
{
    background-color: var(--doi-tool-hover-background-color);
}

.doi-page-footer .doi-storage-consent
{
	padding: 1rem 1rem;
	text-align: center;
	background-color: var(--doi-storage-consent-background-color);
	color: var(--doi-storage-consent-text-color);
	font-size: 90%;
}

.doi-page-footer .doi-storage-consent p
{
	padding: 0 10%;
}

.doi-page-footer .doi-storage-consent .doi-dialog-buttons .btn
{
	margin: 0.5rem 1rem;
}

/**
 * Breadcrumbs.
 */

.doi-breadcrumb::after
{
	color: var(--doi-breadcrumb-separator-color);
	content: "/";
}

.doi-breadcrumb:last-child::after
{
	content: "";
}

/**
 * Drag.
 */

a:active
{
	background-color: var(--doi-background-color);
}

/**
 * Views.
 */

.doi-background-secondary
{
	background-color: var(--doi-secondary-background-color);
}

.doi-dialog-buttons .btn
{
	padding: var(--doi-dialog-button-padding-y) var(--doi-dialog-button-padding-x);
}

/**
 *	Toolbars.
 */

li.doi-tool.show > a
{
    background-color: var(--doi-menu-background-color);
}

li.doi-tool > a:focus
{
	outline: thin dashed var(--doi-tool-color);
	text-decoration: none;
}

li.doi-tool > a:focus:not(:focus-visible),
li.doi-tool > a:focus:not(:-moz-focusring)
{
	outline: none;
}

li.doi-tool:not(.disabled) > a:hover {
	background-color: var(--doi-tool-hover-background-color);
	color: var(--doi-tool-hover-color);
	text-decoration: none;
}

span.doi-tool-icon,
span.doi-dropdown-item-icon
{
    color: var(--doi-tool-color);
	min-width: 1.3em;
	text-align: center;
}

span.doi-tool-title,
span.doi-dropdown-item-title
{
	color: var(--doi-tool-color);
}

li.doi-tool.disabled > a span {
    color: var(--doi-tool-disabled-color);
}

li.doi-tool.selected > a .doi-tool-icon
{
    color: var(--doi-tool-selected-color);
}

li.doi-dropdown-item .doi-dropdown-item-check
{
    color: var(--doi-item-selected-color);
}

li.doi-dropdown-item:hover,
li.doi-dropdown-item > a:focus
{
	background: var(--doi-menu-item-hover-background-color);
}

li.doi-dropdown-item:hover > a,
li.doi-dropdown-item:hover > a span
{
	color: var(--doi-tool-hover-color);
}

li.doi-tool.doi-working span.doi-tool-icon
{
	color: var(--doi-tool-working-color);;
}


/* Tooltips. */

.doi-tool .tooltip .tooltip-inner
{
	display: flex;
	flex-direction: column;
	text-align: left;
}

.doi-tool .tooltip .tooltip-inner .doi-tooltip-keymapping
{
	font-size: 80%;
	color: var(--doi-tooltip-keymapping-color)
}

/**
 * Dropdowns.
 */

ul.dropdown-menu
{
	border-color: var(--doi-menu-border-color);
}

/**
 *	Links.
 */

.doi-link
{
	color: var(--primary);
}

.doi-link:hover
{
	color: #22527b;
	text-decoration: underline;
	cursor: pointer;
}

a[href=""].doi-nolink
{
	text-decoration: none;
	color: inherit;
}

a.doi-nolink:focus
{
	text-decoration: none;
    outline: 0;
}

a:focus:not(:focus-visible)
{
	outline: none;
}

/**
 * Cards.
 */

a.doi-card-container:hover
{
	text-decoration: none;
	color: #195a8f;
}

.doi-object-grid .doi-object-grid-item a
{
	background-color: var(--doi-card-background-color);
    border-color: var(--doi-card-border-color);
	box-shadow: var(--doi-card-box-shadow);
	text-decoration: none;
}

.doi-object-grid .doi-object-grid-item a:hover
{
    background-color: var(--doi-card-hover-background-color);
    border-color: var(--doi-card-hover-border-color);
}

/**
 *	Sort.
 */

th.doi-sorted i
{
	font-size: 125%;
}

/**
 * Forms.
 */

.doi-checkbox-box
{
	background-color: var(--doi-form-control-background-color);
	border: 1px solid var(--doi-form-control-border-color);
    border-radius: 0.15rem;
}

.doi-checkbox a,
.doi-checkfield span i
{
	color: var(--doi-text-color);
}

.doi-checked-implied .doi-checkbox-box
{
	color: var(--doi-checkbox-implied-color);
}

.doi-label
{
	color: var(--doi-label-color);
	font-size: 80%;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 0;
}

doi-field.ng-invalid input.form-control,
input.form-control.ng-invalid,
doi-textarea.ng-invalid textarea.form-control,
textarea.form-control.ng-invalid
{
	background-color: var(--doi-input-invalid-background-color);
}

input[type="file"].form-control
{
	height: auto;
}

.doi-icon-muted
{
	color: #ccc;
}

.btn:disabled
{
	cursor: not-allowed;
}

.doi-lookup .form-control:disabled,
.doi-select .form-control:disabled
{
	cursor: not-allowed;
	color: grey;
}

.doi-lookup .show .dropdown-menu,
.doi-select .show .dropdown-menu
{
	display: block;
	cursor: pointer;
	margin-top: 0;
}

.doi-lookup .input-group .form-control,
.doi-select .input-group .form-control
{
	width: 100%;
}

.doi-lookup .input-group .input-group-btn,
.doi-select .input-group .input-group-btn
{
	width: 1%;
}

.doi-lookup .dropdown-item,
.doi-select .dropdown-item
{
	padding: 0.25rem;
}

.doi-lookup .dropdown-item a,
.doi-select .dropdown-item a
{
	display: block;
	width: 100%;
	color: inherit;
}

.doi-lookup .dropdown-item a:hover,
.doi-select .dropdown-item a:hover
{
	text-decoration: none;
}

.doi-lookup .dropdown-item a.selected,
.doi-select .dropdown-item a.selected
{
	font-weight: bold;
}

.doi-lookup .form-control[readonly],
.doi-select .form-control[readonly]
{
	background-color: inherit;
	cursor: pointer;
}

/**
 * Navigation.
 */

 .nav-tabs a.active
{
	font-weight: bold;
}

/**
 * Dialogs.
 */

.modal-content
{
	background-color: var(--doi-dialog-background-color);
}

/**
 * Text.
 */
.doi-text-small
{
	font-size: 80%;
}

/**
 * Keyboard focus.
 */

a:focus,
.btn:focus,
.form-control:focus
{
	outline: 1px dotted var(--doi-form-control-focus-border-color);
}

a:focus:not(:focus-visible),
.btn:focus:not(:focus-visible),
.form-control:focus:not(:focus-visible)
{
	outline: none;
}

/**
 * Accessibility.
 */

label.doi-accessible
{
	display: none;
}

doi-search-tool input.form-control::placeholder,
doi-filter input.form-control::placeholder
{
	color: #999 !important;
	opacity: 1;
}

/**
 *	Debug.
 */

.doi-debug
{
	background-color: var(--doi-debug-background-color);
	color: var(--doi-debug-color);
}

