/**
 * Variables.
 */

:root
{
	--doi-search-count-color: #666;
	--doi-search-count-background-color: #eed;
}

.doi-search-tool form
{
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	padding: 0 5px;
}

.doi-search-tool form label
{
	margin-bottom: 0;
    padding-right: 0.5em;
}

.doi-search-tool .doi-search-button
{
	display: flex;
	align-items: center;
	width: 0;
	transform: translateX(-1.7em);
}

.doi-search-tool .doi-search-button i
{
    color: #666;
	padding: 0.3em;
	cursor: pointer;
}

.doi-search-tool.doi-search-disabled .doi-search-button i
{
	cursor: wait;
}

.doi-search-form
{
	padding: 0 0.75rem;
}

.doi-search-form,
.doi-search-form form
{
	width: 100%;
}

.doi-search-form .form-group
{
	margin-bottom: 0;
}

.doi-search-form div.row div
{
	display: flex;
	align-items: center;
}

.doi-search-form div.row div doi-field
{
	flex-grow: 1;
	width: auto;
}

.doi-search-form div.row div doi-select
{
	width: auto;
}

.doi-search-form .form-group
{
	margin: 0;
}

.doi-search-form div.row div button
{
	margin-left: 0.2em;
	white-space: nowrap;
}

.doi-search-form div.row div button .fa-search
{
	margin-right: 0.2em;
}

.doi-search-result
{
	display: flex;
	flex: 0 1 auto;
	height: 0;
	margin-top: 1rem;
}

.doi-search-result-filter-pane
{
	overflow-x: hidden;
}

.doi-search-result-filter-pane i
{
	display: none;
}

.doi-search-categories h3,
.doi-search-result-page-header h3
{
	font-size: inherit;
	font-weight: bold;
	white-space: nowrap;
}

.doi-search-categories
{
	display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow-y: auto;
	min-height: 50px;
	min-width: 6rem;
	margin-top: 0.5rem;
}

.doi-search-cat-group
{
	margin-right: 10px;
}

.doi-search-cat-group h3
{
	margin: 0.5rem 0;
}

.doi-search-cat-group:first-child h3
{
	margin-top: 0;
}

.doi-search-cat-group-ec a
{
	width: 100%;
	text-align: center;
	display: block;
	font-size: 80%;
	margin: 1px;
}

.doi-search-cat-group a i
{
	margin-right: 0.5em;
}

.doi-search-cat > div
{
	display: flex;
	justify-content: space-between;
	font-size: 90%;
	margin-bottom: 0.2rem;
}

.doi-search-cat .doi-checkbox input
{
	transform: scale(0.9);
	vertical-align: -1px;
}

.doi-search-cat .doi-checkbox
{
	overflow-x: hidden;
}

.doi-search-cat .doi-checkbox label
{
}

.doi-search-cat .doi-search-cat-count
{
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 3rem;
	width: 3rem;
	min-width: 3em;
	max-width: 3em;
}

.doi-search-cat .doi-search-cat-count span
{
	color: var(--doi-search-count-color);
	background-color: var(--doi-search-count-background-color);
	border-radius: 10px;
	padding: 0 0.4rem;
}

.doi-search-result-page
{
	display: flex;
    flex: 1 1 auto;
	flex-direction: column;
	margin-left: 0;
}

.doi-search-result-page-header
{
	display: flex;
    flex: 0 0 auto;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.doi-search-result-page-header h3
{
	margin-top: 0;
}

.doi-search-result-page-header nav
{
	margin-top: -0.5rem;
}

.doi-search-result-page-header .pagination
{
	margin-bottom: 0;
}

.doi-search-entries
{
	display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow-y: auto;
	min-height: 50px;
	margin-top: 0;
}

.doi-search-entry
{
    margin-bottom: 0.5rem;
    padding-top: 0;
    /*border-top: #CCC solid 1px;*/
}

.doi-search-entry > a
{
}

.doi-search-entry > a:hover
{
	text-decoration: none;
}

.doi-search-entry > a:hover .doi-search-entry-title
{
	text-decoration: underline;
}

.doi-search-entry-header
{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.doi-search-entry-header:hover
{
	background-color: var(--doi-row-hover-background-color);
}

.doi-search-entry-type
{
	margin-right: 0.2em;
}

.doi-search-entry-link
{
	display: inline-block;
}

.doi-search-entry-link a
{
	max-width: 40em;
	display: flex;
	align-items: baseline;
	overflow-x: hidden;
	text-overflow: ellipsis;
	margin: 1px;
}

.doi-search-entry-icon
{
	width: 1.5rem;
	text-align: center;
	margin-right: 0.2rem;
}

.doi-search-entry-body
{
    color: var(--doi-text-color);
	font-size: 80%;
	margin: 0;
}

.doi-search-result-page-footer
{
	margin-top: 0.5rem;
	display: flex;
	justify-content: center;
}

.doi-search-pager
{
}

.doi-search-pager ul
{
	margin-bottom: 0;
}

@media (max-width: 991.999px)
{
	.doi-search-result .doi-search-result-filter-pane
	{
		padding: 0;
		background-color: transparent;
	}

	.doi-search-result .doi-search-result-filter-pane i
	{
		display: block;
		margin-left: 1em;
	}

	.doi-view h3.search-result-filter-header
	{
		display: flex;
		align-items: baseline;
		cursor: pointer;
	}

	.search-categories-hidden
	{
		display: none;
	}

	.doi-search-categories
	{
	}
}
