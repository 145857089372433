
.doi-drag-source-active
{
}

.doi-drop-target-active a
{
}

.doi-drop-target-disallowed
{
	cursor: no-drop;
}

.doi-drop-target-disallowed a
{
}

.doi-drop-target-after:not(.doi-drop-target-into)
{
	padding-bottom: 2px;
	margin-bottom: 2px;
	border-bottom: 2px solid;
}

.doi-drop-target-after.doi-drop-target-into i
{
	padding-bottom: 5px;
}

.doi-drop-target-after.doi-drop-target-into a span
{
	padding: 2px 4px 2px 0px;
	padding-bottom: 2px;
	border-bottom: 2px dotted;
	margin-bottom: 2px;
	border-right: 3px dotted;
	background-color: #ddd;
}

.doi-drop-target-before
{
	padding-top: 2px;
	margin-top: 2px;
	border-top: 2px solid;
}

.doi-drop-target-active *
{
	pointer-events: none;
}

