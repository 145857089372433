/**
 * Page layout.
 */

.doi-html,
.doi-body,
.doi-app
{
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	overscroll-behavior: contain;
}

.doi-app-container
{
	display: flex;
	flex-flow: column;
	height: 100%;
	width: 100%;
}

.doi-app-pane-container
{
	display: flex;
	flex-flow: row;
	height: 100%;
	width: 100%;
	position: relative;
}

.doi-app-pane
{
	display: flex;
	flex-flow: column;
	height: 100%;
	width: 100%;
	padding: 0;
}

.doi-page-header
{
	display: flex;
	justify-content: space-between;
    align-items: center;
	min-height: 3rem;
	padding: 0 15px;
}

.doi-page-header-title
{
	display: flex;
}

.doi-page-header-title .doi-tools
{
	margin-right: 2px;
}

.doi-page-header h1
{
	display: flex;
	align-items: center;
	font-size: 20px;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
}

.doi-page-header h1 a
{
}

.doi-page-header h1 a i
{
	display: inline-block;
	margin-right: 8px;
}

.doi-page-header h1 a i img
{
	width: 24px;
}

.doi-page-content
{
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	padding: 0;
}

.doi-page-content > *
{
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
}

.doi-page-footer
{
	flex: 0 0 auto;
}

.doi-page-footer span
{
	display: inline-block;
}

.doi-alert
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px;
}

.doi-alert-close
{
	font-size: 80%;
}

router-outlet
{
	max-height: 0;
}

router-outlet + *
{
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
}

/**
 * Spread out children. Used for bars, footers, etc.
 */

.doi-bar
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
}

.doi-view-title
{
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.doi-view-title .doi-header
{
	margin-top: 5px;
	margin-bottom: 10px;
}

.doi-view-title .doi-header .form-group
{
	margin-top: 5px;
}

.doi-view-title .doi-title
{
	padding-left: 0;
}

.doi-title
{
	display: flex;
	padding-left: 15px;
}

.doi-view-tabs
{
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 10px;
}

.doi-view-tabs > ul > li > a
{
	padding: 0.5rem 10px;
}

.doi-view .doi-scroll-container:last-child table
{
	margin-bottom: 0.5rem;
}

.doi-view-footer
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.doi-object-list
{
	list-style: none;
	padding-left: 0;
}

.doi-object-list a
{
	display: flex;
	align-items: baseline;
}

.doi-object-list i,
.doi-icon
{
	margin-right: 0.5em;
	min-width: 1.5em;
	text-align: center;
}

/**
 *	Toolbars.
 */

ul.doi-tools
{
	display: flex;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0 calc(-1*var(--doi-tool-padding-x)) 0 calc(-1*var(--doi-tool-padding-x));
}

doi-viewmode-tools,
doi-object-edit-tools,
doi-table-edit-tools
{
	display: flex;
}

li.doi-tool
{
	display: flex;
}

li.doi-tool > a
{
	cursor: pointer;
}

li.doi-tool.disabled > a
{
	cursor: default;
}

li.doi-tool > a
{
	display: flex;
	align-items: center;
	padding: var(--doi-tool-padding-x) var(--doi-tool-padding-y);
	border: 1px solid transparent;
}

li.doi-tool.show > a
{
	background-color: white;
	color: black;
	border: 1px transparent;
	margin: 1px;
}

/**
 *	Breadcrumbs.
 */

.doi-breadcrumbs
{
	display: flex;
	align-items: center;
	padding: 0;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	background-color: transparent;
}

.doi-breadcrumbs .doi-breadcrumbs-home i
{
    font-size: larger;
    margin-right: 0.1em;
}

.doi-breadcrumb::after
{
	padding: 0 0.2em;
	vertical-align: top;
}

.doi-breadcrumbs li
{
	display: flex;
	align-items: center;
}

.doi-breadcrumbs a
{
	max-width: 15em;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;
}

/**
 * Lists.
 */

.doi-view .doi-titled-list
{
}

.doi-view .doi-titled-list h3
{
	margin-top: 0.5em;
}

/**
 * Tabs.
 */

.nav-tabs .nav-link
{
  display: flex;
  align-items: center;
}

.nav-tabs .nav-link i
{
	font-size: 75%;
	margin-right: 0.5em;
}

/**
 * Tables
 */

table.doi-table-fixed
{
	table-layout: fixed;
}

th.doi-table-toolbar
{
	justify-content: flex-end;
}

/**
 * Fields.
 */

.doi-field-header
{
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.doi-textarea-wrapper
{
	display: flex;
	align-items: flex-end;
}

.doi-textarea-wrapper i
{
	transform: translateX(-0.8rem) translateY(-0.1rem) scale(0.6, 1);
	font-size: 80%;
	width: 0;
}

/**
 * Dialogs.
 */
.doi-modal-dialog .btn
{
	min-width: 4.5rem;
}
