/**
 * Row header and tools.
 */

.doi-table-edit-tools li.doi-tool > a
{
	padding: var(--doi-tool-padding-xs) var(--doi-tool-padding-xs);
}

.doi-table-editing .doi-row-editable
{
	cursor: text;
}

.doi-table-editing .doi-row-editable .form-group
{
	padding: calc(0.2rem + 1px);
}

.doi-table-editing .doi-row-editable .doi-not-editing
{
	max-height: 10em;
	overflow-y: hidden;
}

th.doi-table-row-header,
td.doi-table-row-header
{
	width: 2rem;
	min-width: 2rem;
	max-width: 2rem;
	padding: 0;
	flex-grow: 0;
	font-size: 0.8rem;
}

doi-row-header:not(.selected) i
{
	color: transparent;
}

doi-row-header .doi-checkbox-box
{
	border-radius: 0.3rem;
}

doi-row-header.selected.anchor .doi-checkbox-box
{
	border-color: black;
}


tr doi-row-header {
	width: 100%;
	display: flex;
}

tr doi-row-header.doi-checkbox
{
	overflow: visible;
}

tr doi-row-header.doi-checkbox a
{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	margin: 0;
}

tr td doi-row-header.doi-checkbox a
{
	margin-top: 0.5rem;
}

tr doi-row-header.doi-checkbox a:active
{
	background-color: inherit;
}

tr.doi-row-mark-deleted
{
	position: relative;
}

tr.doi-row-mark-deleted td::before
{
	content: '';
	position: absolute;
	width: 100%;
    height: 1.1rem;
	border-bottom: solid 1px red;
	background-color: transparent;
}

tr.doi-row-mark-deleted td.doi-table-row-header::before
{
	content: none;
}
