/**
 * Hide body until theme is loaded.
 */
.doi-body
{
	display: none;
}

/**
 * Page header icon.
 */

.doi-page-header h1 a i
{
	border-radius: 50%;
	background-color: #f6f6f6;
	margin-right: 8px;
}

.doi-page-header h1 a i img
{
	height: 32px;
	width: 32px;
    padding: 5px;
}

.doi-page-header .suffix
{
	margin-left: 0.3em;
}

/**
 * Skip to content
 */

.doi-page-header .doi-tool.skip-to-content > a
{
	padding: 0;
	border: none;
}

.doi-page-header .doi-tool.skip-to-content > a span
{
	display: none;
}

.doi-page-header .doi-tool.skip-to-content > a:focus
{
}

.doi-page-header .doi-tool.skip-to-content > a:focus span
{
	display: inline-block;
	padding: 6px;
}

.doi-page-header .doi-tool.skip-to-content > a:focus:not(:focus-visible)
{
	padding: 0;
}

.doi-page-header .doi-tool.skip-to-content > a:focus:not(:focus-visible) span
{
	display: none;
}

/**
 * Hide search tool on views with own search field.
 */

.no-search-tool li.search-tool
{
	display: none;
}

.no-search-tool li.search-tool form,
{
	padding: 0;
}

/**
 * Start view.
 */

.start-title
{
	padding: 0 15px;
	margin-bottom: 1.5rem;
	margin-right: 0;
}

.start-title h2
{
	font-size: 2.5rem;
}

.start-title .col-12
{
    display: flex;
    justify-content: space-between;
}

.start-title h2,
.start-title h3
{
}

.start-version
{
	margin-top: -22px;
	margin-bottom: 22px;
	color: #555;
}

.start-menu
{
	margin-top: 2px;
}

.start-menu .col
{
	margin-bottom: 15px;
}

.start-menu .card-title
{
	font-size: 1.2rem;
	font-weight: bold;
}

.start-menu .card
{
    height: 100%;
}

.start-menu .card-text
{
    color: var(--doi-text-color);
}


.start-view .menu
{
	margin-top: 1.0rem;
}

.start-view .menu a.menu-item
{
	display: block;
}

.start-view .menu a.menu-item:hover
{
	background: #EEE;
	text-decoration: none;
}

.start-view .menu a.menu-item .card
{
	background: transparent;
	text-decoration: none;
}

.start-view .menu a.menu-item .card-text
{
	min-height: 3em;
}

/**
 * Object grid items.
 */

.doi-object-grid-item-body
{
	margin-top: 0.5em;
	font-size: 90%;
}

.doi-title h2
{
	display: flex;
	flex-direction: column;
}

.doi-title .title-object-type
{
	font-size: 70%;
	text-transform: uppercase;
}

/**
 * Media.
 */
@media (orientation:landscape) {
    .hidden-landscape {
        display: none!important;
    }
    .visible-landscape {
        display: inherit!important;
    }
    .visible-cell-landscape {
        display: table-cell!important;
    }
}

@media (orientation:portrait) {
    .hidden-portrait {
        display: none!important;
    }
    .visible-portrait {
        display: inherit!important;
    }
    .visible-cell-portrait {
        display: table-cell!important;
    }
}

/**
 *	Search view.
 */

body .doi-search-entry-link
{
	margin-right: 2em;
	overflow-x: hidden;
}

.search-entry-cat-link
{
	display: inline-block;
	width: 30%;
	min-width: 30%;
	max-width: 30%;
	font-size: 85%;
}

/**
 *	Settings view.
 */
.settings-view .themes .card
{
	height: 100%;
	margin: 1px;
	min-height: 7rem;
}

/**
 * Property values.
 */

.property-values
{
}

.property-value-highlighted
{
	line-height: 1.5em;
}

.property-value-highlighted:last-child
{
  margin-bottom: 0.2rem;
}

.property-values-normal
{
}

.property-value
{
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.property-value-revert
{
	font-size: 80%;
	padding-left: 1em;
	line-height: 1em;
}

.property-value form
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.property-value .doi-checkbox
{
  padding-bottom: 0.5em;
  display: inline-block;
}

.property-value-highlighted i
{
	margin-right: 0.5em;
}

.property-value .doi-label
{
	width: inherit;
}

.property-value .doi-label:hover
{
	text-decoration-line: underline;
	text-decoration-style: dotted;
}

.property-value .doi-label[ng-reflect-ngb-tooltip=""]:hover
{
  text-decoration-line: none;
}

.property-value-description .tooltip-inner
{
	text-align: left;
	white-space: pre-line;
	color: black;
	background-color: white;
	padding: 15px;
	border-radius: 10px;
	box-shadow: 0 0 3px 0;
	max-width: 300px;
}

/**
 * The unit component
 */
.unit-header
{
	display: flex;
	justify-content: space-between;
}

.unit-property-values
{
	margin-top: 0.5rem;
}

/**
 * Miscellaneous.
 */

.side-label
{
	font-size: 80%;
	color: #333;
	margin-top: 0.15rem;
	line-height: 1rem;
	margin-bottom: 0.2rem;
	font-weight: bold;
}

.object-file a
{
	display: flex;
	align-items: baseline;
}

ul.cell-list
{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

doi-title span.title-object-type
{
	margin-right: 0.3em;
}

doi-title span.title-object-name
{
}

.notes
{
	white-space: pre-line;
	padding: 0.25em;
}

.date-range
{
  display: flex;
}

.date-range .doi-field
{
	margin-right: 0.5rem;
	width: auto;
	min-width: 10rem;
}

.date-range .doi-field.date
{
	min-width: 15rem;
}
