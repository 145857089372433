/**
 * Variables.
 */

:root
{
	--doi-navigator-icon-color: inherit;
	--doi-navigator-popout-background-color: #6663;
	--doi-navigator-popout-border-color: #555;
}

.doi-navigator-pane
{
	border-right: 0.5rem solid var(--doi-page-header-background-color);
	background-color: var(--doi-secondary-background-color);
	flex-basis: 30%;
	min-width: 4em;
}

.doi-navigator-pane a:active
{
	background-color: var(--doi-secondary-background-color);
}

.doi-navigator-pane li.doi-tool-navigatordocked.selected > a .doi-tool-icon
{
	transform: rotate(0deg);
	color: inherit;
}

.doi-navigator-pane li.doi-tool-navigatordocked > a .doi-tool-icon
{
	transform: rotate(90deg);
}

.doi-navigator
{
	display: flex;
	flex: 1 1 auto;
	height: 0;
	margin-left: 0.5rem;
	margin-right: 2px;
	overflow-y: auto;
    overflow-x: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.doi-navigator ul
{
	list-style: none;
	padding-left: 1.5rem;
}

.doi-navigator li
{
	white-space: nowrap;
}

.doi-navigator ul.doi-nav-root
{
	max-width: 100%;
	list-style: none;
	padding-left: 0;
	margin-top: 0.5rem;
}

.doi-navigator ul.doi-nav-root.doi-nav-expanded-always
{
  margin-left: -1rem;
}

.doi-navigator ul.doi-nav-root > li
{
	margin-bottom: 0.2em;
}

.doi-navigator a:focus:not(:focus-visible)
{
	outline: none;
}

.doi-nav-node-link
{
	display: flex;
	align-items: center;
}

.doi-nav-node-link-label
{
	padding-right: 5px;
}

.doi-nav-node-link a
{
	margin-left: 1px;
}

.doi-nav-node-link:hover
{
	text-decoration: none;
}

.doi-nav-node-link:hover .doi-nav-node-label
{
	text-decoration: underline;
}

.doi-nav-node-exp
{
	width: 1.5rem;
	text-align: center;
}

.doi-nav-node-icon
{
	color: var(--doi-navigator-icon-color);
	width: 1.5em;
	text-align: center;
}

.doi-nav-node-label
{
	white-space: nowrap;
	overflow-x: hidden;
	margin-left: 0.3rem;
}

.doi-nav-node-leaf .doi-nav-node-exp
{
	color: transparent;
}

.doi-nav-node-selected > .doi-nav-node-row > a
{
	font-weight: bold;
}

.doi-nav-node-row
{
	margin-left: 3px;
}

.doi-navigator-wrapper
{
    display: flex;
	flex-direction: column;
	height: 100%;
}

.doi-navigator-pane.doi-navigator-popout .doi-navigator-wrapper
{
	width: max-content;
	max-width: 100%;
	border-right: 2px solid var(--doi-navigator-popout-border-color);
}

.doi-navigator-pane .doi-navigator-header
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
}

.doi-navigator-pane .doi-navigator-footer
{
    display: flex;
	flex-flow: column;
    align-items: center;
    padding: 0 15px;
}

.doi-navigator-pane .doi-navigator-footer > div
{
	margin: 0.5rem 0;
}
.doi-navigator-pane.doi-navigator-popout
{
	background: var(--doi-navigator-popout-background-color);
	position: fixed;
	z-index: 1;
	border-right: none;
}

.doi-navigator-pane.doi-navigator-popin
{
	display: none;
}

.doi-navigator-pane.doi-navigator-popout .doi-navigator
{
	width: max-content;
	padding-right: 1em;
}

/**
 * Navigator to the right.
 */

.doi-navigator-right .doi-app-pane-container
{
	flex-direction: row-reverse;
}

.doi-navigator-right .doi-navigator-pane
{
	border-left: 0.5rem solid var(--doi-page-header-background-color);
	border-right: none;
}

.doi-navigator-right .doi-navigator-pane.doi-navigator-popout
{
	flex-direction: row-reverse;
}

.doi-navigator-right .doi-navigator-pane.doi-navigator-popout
{
	flex-direction: row-reverse;
}

.doi-navigator-right .doi-navigator-pane.doi-navigator-popout .doi-navigator-wrapper
{
	border-right: none;
	border-left: 2px solid var(--doi-navigator-popout-border-color);
}

.doi-navigator-right .doi-navigator ul
{
	padding-left: 1rem;
}
