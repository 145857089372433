@import "doi-layout.css";
@import "doi-dnd.css";
@import "doi-table-editor.css";
@import "doi-theme.css";

/**
 * Dropdowns.
 */

ul.dropdown-menu
{
	padding: 0;
	border-radius: 0;
	margin-top: -0.2rem;
}

li.doi-dropdown-item .doi-dropdown-item-check
{
	font-size: 75%;
	min-width: 1em;
	margin-right: 0.2rem;
}

li.doi-dropdown-item
{
	padding: 0;
}

li.doi-dropdown-item:first-child {
    border-top: none;
}

li.doi-dropdown-item.doi-separator-before {
    border-top: 1px solid #ccc;
}

li.doi-dropdown-item.doi-separator-after {
    border-bottom: 1px solid #ccc;
}

li.doi-dropdown-item > a
{
    padding: .3rem 1.5rem .3rem 0.5rem;
	display: flex;
	align-items: center;
}

li.doi-dropdown-item.disabled {
    display: none;
}

li.doi-tool .dropdown-menu
{
	padding: 2px 0;
}

span.doi-dropdown-item-icon
{
	margin-right: 0.5em;
}

span.doi-tool-dropdown-icon
{
	text-align: right;
	font-size: 67%;
	width: 0.5rem;
	height: 0.5rem;
	margin-left: -0.5rem;
	vertical-align: bottom;
	display: none;
	transform: translate(0.3rem, 0.5rem);
}

a:hover span.doi-tool-dropdown-icon
{
	display: inline-block
}

span.doi-tool-title
{
	display: inline-block;
	margin-left: 0.25em;
}

/**
 *	Views
 */

.doi-view
{
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
}

.doi-view h2
{
	font-size: 1.5rem;
	margin: 0.5rem 0;
}

.doi-view h3
{
	font-size: 1rem;
	margin: 0.5rem 0;
}

.doi-view h4
{
	font-size: 0.8rem;
	margin: 0.5rem 0;
}

.doi-view h5
{
	font-size: 0.8rem;
	margin: 0.5rem 0;
}

.doi-login-view
{
	margin: 0 auto;
	padding-top: 10%;
	width: 44em;
}

.doi-login-view .doi-login-provider
{
	display: flex;
}

.doi-login-view .doi-login-provider i,
.doi-login-view .doi-login-provider img
{
	width: 1.3em;
	margin: 0 0 0 2px;
}

.doi-login-view .doi-login-provider i
{
	text-align: center;
}

.doi-login-view .doi-login-provider span
{
	width: 1em;
	margin-left: var(--doi-tool-padding-x);
}

.doi-view-title h2
{
	font-size: 1.2rem;
	margin-bottom: 0;
}

.doi-view-title .doi-not-editing .doi-label {
	display: none;
}

.doi-view-title .doi-editing .doi-label {
	display: block;
}

.doi-field .doi-unlabeled-empty
{
	display: none;
}

/**
 *	Sub views.
 */
.doi-subview
{
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	padding: 0.75rem;
}

/**
 *	Object grid.
 */

.doi-object-grid
{
	display: flex;
	align-content: flex-start;
}

.doi-object-grid .doi-object-grid-item
{
	margin-bottom: 1rem;
}

.doi-object-grid .doi-object-grid-item a
{
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0.5rem 1rem;
	border: 1px solid var(--doi-card-border-color);
	border-radius: var(--doi-card-border-radius);
}

.doi-object-grid-item-header
{
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.doi-object-grid-item-body
{
	display: flex;
}

.doi-object-grid .doi-object-grid-item-title
{
	flex: 1 1 auto;
	min-height: 1.5em;
}

.doi-object-grid .doi-object-grid-item a:hover
{
	text-decoration: none;
}

.doi-object-grid .doi-object-grid-filler
{
	flex: 1 0 auto;
	padding-bottom: 0px;
	width: 250px;
}

.doi-object-grid a
{
}

.doi-object-grid a.list-group-item:hover
{
	background-color: #eee;
}

.doi-object-grid span.infotag
{
}

.doi-infotag
{
	color: grey;
}

.doi-object-grid img
{
	height: 16px;
	width: 16px;
	margin-right: 0.5rem;
}

.doi-object-grid .doi-object-grid-item-icon
{
	margin-right: 0.5rem;
	width: 16px;
	text-align: center;
}

/**
 *	Scrolling.
 */

.doi-scroll
{
	display: flex;
	flex-flow: column nowrap;
	flex: 1 0 auto;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 0;
	min-height: 50px;
	width: 100%;
}

.doi-scroll-container,
.doi-scroll-container > table,
.doi-scroll-expandable-container,
.doi-scroll-expandable-container > table
{
	display: flex;
	flex-flow: column nowrap;
	flex: 1 0 auto;
}

.doi-scroll-container > table thead,
.doi-scroll-expandable-container > table thead
{
	display: flex;
	flex-flow: column nowrap;
	flex: 0 0 auto;
	overflow-x: hidden;
	width: 100%;
	/* No overlay in FF Windows and in Chrome. Workaround: */
	scrollbar-gutter: stable;
}

.doi-scroll-container > table tbody,
.doi-scroll-expandable-container > table tbody
{
	display: flex;
	flex-flow: column nowrap;
	flex: 1 0 auto;
	overflow-y: overlay;
	overflow-x: hidden;
	height: 0;
	min-height: 50px;
	width: 100%;
	/* No overlay in FF Windows and in Chrome. Workaround: */
	scrollbar-gutter: stable;
}

.doi-scroll-container > table thead,
.doi-scroll-expandable-container > table thead,
.doi-scroll-container > table tbody,
.doi-scroll-expandable-container
{
}

.doi-scroll-container > table tr,
.doi-scroll-expandable-container > table tr
{
	display: flex;
	flex-flow: row nowrap;
	flex: 0 0 auto;
	width: 100%;
}

.doi-scroll-container > table th,
.doi-scroll-container > table td,
.doi-scroll-expandable-container > table th,
.doi-scroll-expandable-container > table td
{
	display: flex;
	flex-flow: row;
	flex: 1 0 0;
}

.doi-scroll-expandable-container
{
	& table > thead > tr {
		/* Header row of expanded table */
		border: none;
	}

	& table > tbody {
		min-height: unset;
	}

	& table tr {
		border: 1px solid var(--bs-inner-table-header-bgcolor);
		border-left: none;
		border-right: none;
		border-bottom: none;
		transition: 0.05s;

		& i {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	/* Hover background color, affecting all tr except selected tr as well as selected tr's underlying th's */
	& table tbody tr:not(.selected-row, .selected-row-expanded-content, .inner-table-toggable > thead > tr):hover {
		background-color: var(--doi-row-hover-background-color); /* #f8f8f8 */
	}

	/*  */
	& table > tbody > tr.selected-row {
		background-color: var(--doi-link-hover-color);
		color: #fcfcfc;
	}

	& .selected-row-expanded-content {
		border-color: var(--doi-link-hover-color);
		border-style: solid;
		border-top: none;
		padding-top: 0;
		margin-bottom: 1rem;
		box-shadow: -4px 3px 15px -8px rgba(53 53 53 / 63%);
		& td {
			animation: fadeIn 0.2s forwards;
			white-space: pre-line;
			display: flex;
			align-items: center;
		}
	}

	& .selected-row-expanded-content:hover {
		background-color: unset;
	}
}

.inner-table-toggable > tbody > tr > td {
	border: 1px solid var(--bs-inner-table-header-bgcolor);
	border-top: none;
	border-bottom: none;
	border-right: 0;
	padding-left: 0.5em;

	&:last-child {
		border-right: none;
	}

	&:first-child {
		border-left: none;
	}
}

.inner-table-toggable > thead > tr > th:nth-child(n+2) {
	padding-left: 0.5em;
}

.outer-table-body {
	height: unset;
}

.inner-table-toggable {
	width: 100%;

	& thead {
		background: var(--bs-inner-table-header-bgcolor);
	}

	& thead > tr > th {
		font-weight: 500;
	}

	& tbody > tr > th {
		background-color: var(--bs-inner-table-header-bgcolor);
		flex: none;

		& span {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	& thead > tr > th:nth-child(1),
	& tbody > tr > td:nth-child(1) {
		width: auto;
		white-space: nowrap;
		flex: none;
    }
}

.doi-scroll-expandable-container > table > .outer-table-body > .selected-row-expanded-content > td > .inner-table-toggable > tbody {
	height: unset;
}

.pointer-cursor {
	cursor: pointer;

	&:nth-child(odd) {
		background-color: #0000000d;
	}
	& > td {
		padding: 0.5em 0;
	}
}

.hidden-icon {
	visibility: hidden;
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

table .doi-width-1   { width:   1rem; }
table .doi-width-1p  { width: 1.5rem; }
table .doi-width-2   { width:   2rem; }
table .doi-width-3   { width:   3rem; }
table .doi-width-4   { width:   4rem; }
table .doi-width-5   { width:   5rem; }
table .doi-width-10  { width:  10rem; }
table .doi-width-15  { width:  15rem; }
table .doi-width-20  { width:  20rem; }

.doi-scroll-container > table .doi-width-1   { min-width:   1rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-1p  { min-width: 1.5rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-2   { min-width:   2rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-3   { min-width:   3rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-4   { min-width:   4rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-5   { min-width:   5rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-10  { min-width:  10rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-15  { min-width:  15rem; flex-grow: 0; }
.doi-scroll-container > table .doi-width-20  { min-width:  20rem; flex-grow: 0; }

.doi-scroll-container > table .doi-grow-0 { flex-grow: 0; }
.doi-scroll-container > table .doi-grow-1 { flex-grow: 1; }
.doi-scroll-container > table .doi-grow-2 { flex-grow: 2; }
.doi-scroll-container > table .doi-grow-3 { flex-grow: 3; }
.doi-scroll-container > table .doi-grow-4 { flex-grow: 4; }
.doi-scroll-container > table .doi-grow-5 { flex-grow: 5; }

.doi-scroll .row
{
	flex-shrink: 0;
}

.doi-scroll table
{
	flex: 1 1 auto;
}

/**
 *	Icons
 */

.icon-text {
	white-space: nowrap;
}

.icon-text img {
	margin-right: 4px;
	margin-bottom: 4px;
}

/**
 *	Forms
 */

.doi-view .form-group
{
	margin-top: 0.1rem;
	margin-bottom: 0.3rem;
}

label.doi-label
{
	display: inline-block;
	width: 100%;
}

.doi-field span
{
	white-space: pre-line;
}

.doi-field.doi-strong .doi-field-text
{
	font-weight: bold;
}

.doi-field .input-group
{
	flex-wrap: nowrap;
}

.doi-field .input-group .dropdown-toggle
{
	width: 100%;
}

.doi-field input.form-control
{
	width: 100%;
}

.doi-checkbox,
.doi-checkfield
{
    white-space: nowrap;
	overflow-x: hidden;
}

.doi-checkbox a
{
	margin: 2px;
}

.doi-checkbox a,
.doi-checkfield span i
{
	display: block;
}

.doi-checkbox a:hover,
.doi-checkfield a:hover
{
	text-decoration: none;
}

.doi-checkbox a:focus:not(:focus-visible),
.doi-checkfield a:focus:not(:focus-visible)
{
    outline: 0;
}

.doi-checkfield a,
.doi-checkfield a:focus,
.doi-checkfield a:active
{
	border: none;
	box-shadow: none;
	padding-left: 0;
}

.doi-checkbox-box
{
    width: calc(1em + 4px);
	height: calc(1em + 4px);
	padding: 1px;
	margin: 0 -4px -2px 0;
}

.doi-unchecked .doi-checkbox-box
{
	color: transparent;
}

.doi-checkbox-label
{
	margin-left: 0.7em;
	cursor: inherit;
}

/**
 *	Widgets
 */

.doi-filter .doi-field
{
	margin: 0;
}

.doi-field
{
	width: 100%;
}

.doi-select
{
	width: 100%;
}

.doi-textarea
{
	width: 100%;
}

/**
 *	Tables
 */

.table > thead > tr > th
{
    border-top: none;
    border-bottom: none;
}

.table > thead > tr > th,
.table > tbody > tr > td
{
	padding: 2px 0.25rem 2px 0;
	border-top: none;
}

.table > thead > tr
{
}

.table > tbody > tr
{
}

td
{
	white-space: nowrap;
}

td.doi-wrap
{
	white-space: normal;
}

tr.table-total
{
	font-weight: bold;
}

.table thead
{
	font-size: 80%;
}

/**
 *	Misc.
 */

input::placeholder
{
    color: #bbb!important;
    opacity: 1;
}

.doi-clickable
{
	cursor: pointer;
}

.doi-sortable
{
	cursor: pointer;
}

div.doi-valign-middle
{
    display: flex;
    align-items: center;
}

div.doi-valign-bottom
{
    display: flex;
    align-items: flex-end;
}

div.doi-valign-baseline
{
    display: flex;
    align-items: baseline;
}

.doi-overlay-icon
{
	display: flex;
	flex-wrap: nowrap;
}

.doi-overlay-icon i
{
	transform: translate(-1em,-0);
	width: 0;
}

.doi-overlay-icon i:first-child
{
	transform: none;
	width: 1em;
}

.doi-line-breaks
{
	white-space: pre-line;
}

/**
 * Responsive hide.
 */

/* Unconditional */
.doi-hidden
{
	display: none !important;
}

/* XS */
@media (max-width: 575.999px)
{
	.doi-hidden-xs,
	.doi-hidden-xs-down, .doi-hidden-sm-down, .doi-hidden-md-down, .doi-hidden-lg-down,
    .doi-hidden-not-sm, .doi-hidden-not-md, .doi-hidden-not-lg, .doi-hidden-not-xl
    {
        display: none !important;
    }

	.expanded-content-grid-row > span,
	.expanded-content-grid-header > span,
	.doi-scroll-expandable-container > table > tbody > tr > td,
	.doi-scroll-expandable-container > table > thead > tr > th
	{
		font-size: 11px;
		white-space: normal;
	}

}

/* SM */
@media (min-width: 576px) and (max-width: 767.999px)
{
	.doi-hidden-sm,
    .doi-hidden-sm-down, .doi-hidden-md-down, .doi-hidden-lg-down,
    .doi-hidden-sm-up,
    .doi-hidden-not-xs, .doi-hidden-not-md, .doi-hidden-not-lg, .doi-hidden-not-xl
    {
        display: none !important;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.999px)
{
	.doi-hidden-md,
    .doi-hidden-md-down, .doi-hidden-lg-down,
    .doi-hidden-sm-up, .doi-hidden-md-up,
    .doi-hidden-not-xs, .doi-hidden-not-sm, .doi-hidden-not-lg, .doi-hidden-not-xl
    {
        display: none !important;
    }
}

/* LG */
@media (min-width: 992px) and (max-width: 1199.999px)
{
	.doi-hidden-lg,
    .doi-hidden-lg-down,
    .doi-hidden-sm-up, .doi-hidden-md-up, .doi-hidden-lg-up,
    .doi-hidden-not-xs, .doi-hidden-not-sm, .doi-hidden-not-md, .doi-hidden-not-xl
    {
        display: none !important;
    }
}

/* XL */
@media (min-width: 1200px)
{
	.doi-hidden-xl,
	.doi-hidden-xl-down,
	.doi-hidden-sm-up, .doi-hidden-md-up, .doi-hidden-lg-up, .doi-hidden-xl-up,
    .doi-hidden-not-xs, .doi-hidden-not-sm, .doi-hidden-not-md, .doi-hidden-not-lg
    {
        display: none !important;
    }
}

/* Landscape */
@media (orientation:landscape) {
    .doi-hidden-landscape
	{
        display: none!important;
    }
}

/* portrait */
@media (orientation:portrait) {
    .doi-hidden-portrait
	{
        display: none!important;
    }
}

/**
 * Bootstrap overrides.
 */

.nav-tabs .nav-link:focus:not(:focus-visible)
{
	outline: none;
}

/**
 * Firefox workaround for missing overlay scrollbar.
 */
@supports not (overflow-y: overlay)
{
	.doi-scroll-container > table tbody
	{
		overflow-y: scroll;
	}
}
